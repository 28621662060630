<template>
  <v-container>
    <breadcrumbs class="mb-4" :items="breadcrumbs"></breadcrumbs>
    <disorder-template
      :heading="$t('bulimia')"
      :next="next"
      :previous="previous"
      :sources="sources"
    >
      <p v-for="paragraph in $t('disorders-bulimia')" :key="paragraph">
        {{ paragraph }}
      </p>
    </disorder-template>
  </v-container>
</template>
<script>
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import DisorderTemplate from "@/components/DisorderTemplate.vue";

export default {
  name: "bulimia",
  components: {
    Breadcrumbs,
    DisorderTemplate
  },
  data: () => ({
    next: {
      slug: "binge-eating",
      url: "/disorders/binge-eating"
    },
    previous: {
      slug: "anorexia",
      url: "/disorders/anorexia"
    },
    sources: [
      "[1] Legenbauer T, Vocks S. Manual der kognitiven Verhaltenstherapie bei Anorexie und Bulimie. Springer Berlin Heidelberg; 2014. doi:10.1007/978-3-642-20385-5",
      "[2] DIMDI - ICD-10-GM Version 2018. Accessed August 4, 2021. https://www.dimdi.de/static/de/klassifikationen/icd/icd-10-gm/kode-suche/htmlgm2018/block-f50-f59.htm"
    ]
  }),
  computed: {
    breadcrumbs: function() {
      return [
        {
          text: this.$t("home"),
          disabled: false,
          href: "/"
        },
        {
          text: this.$t("disorders"),
          disabled: false,
          href: "/disorders"
        },
        {
          text: this.$t("bulimia"),
          disabled: true
        }
      ];
    }
  }
};
</script>
